export const WIN_MESSAGES = [
  "Better luck next Nordle :(",
  "Saved by the Nordle!",
  "Sensible Nordle!",
  "Good Nordle!",
  "Great Nordle!",
  "Now you're Nordlin'!",
  "Incredible Nordle!",
  "Top Nordle!",
  "Peak Nordle!",
  "Penultimate Nordle!",
  "Ultimate Nordle!!"
]
